export const PageDashboard = '/dashboard';
export const PageAccount = '/account';
export const PageAdjustment = '/adjustment';
export const PageDiscount = '/adjustment/discount';
export const PageFee = '/adjustment/fee';
export const PageDevice = '/device';
export const PageDeviceAll = '/device/all';
export const PageDevicePrinters = '/device/printers';
export const PageFloorPlan = '/floor-plan';
export const PageMenuManagement = '/menu-management';
export const PageMenuManagementForAvailability = '/menu-management/availability';
export const PageMenuManagementForMenus = '/menu-management/menus';
export const PageMenuSetup = '/menu-management/menus-backup';
export const PageMenuManagementForCategories = '/menu-management/categories';
export const PageMenuManagementForCategoryEdit = '/menu-management/categories/edit/:id';
export const PageMenuManagementForSalesCategory = '/menu-management/sales-category';
export const PageMenuManagementForPriceEditor = '/menu-management/dishes';
export const PageMenuManagementForDishPreference = '/menu-management/dish-preference';
export const PageMenuManagementForCustomizations = '/menu-management/customizations';
export const PageMenuManagementForDishAdd = '/menu-management/dishes/add';
export const PageMenuManagementPrinterMapping = '/menu-management/printer-mapping';
export const PageMenuDecoration = '/menu-management/menu-decorate';
export const PageBatchUpdatePricing = '/menu-management/dishes/bulk-edit-price';
export const PageBatchUpdateMemberPricing = '/menu-management/dishes/bulk-edit-member-price';
export const PageBulkDiscountExempt = '/menu-management/dishes/bulk-discount-exempt';
export const PageBulkTaxExempt = '/menu-management/dishes/bulk-tax-exempt';
export const PageMenuSettings = '/menu-management/dishes/settings';
export const PageOrders = '/orders';
export const PageClosedOrders = '/orders/closed';
export const PageOpenOrders = '/orders/open';
export const PageReport = '/reports';
export const PageSalesReport = '/reports/sales';
export const PagePayoutReport = '/reports/payout';
export const PagePayoutFeeReport = '/reports/payout-fee';
export const PageCashReport = '/reports/cash-statement';
export const PageInternalMonthlyStatement = '/reports/monthly-internal';
export const PageTeam = '/team-management';
export const PageTeamRolesAndMembers = '/team-management/roles_and_members';
export const PageTeamMembers = '/team-management/roles_and_members/members';
export const PageTeamRoles = '/team-management/roles_and_members/roles';
export const PageTeamDepartment = '/team-management/roles_and_members/departments';
export const PageTeamBreaks = '/team-management/breaks';
export const PageWorkforce = '/team-management/workforce';
export const PageWorkforceProductivity = '/team-management/workforce/productivity';
export const PageWorkforceTimeLog = '/team-management/workforce/time-log';
export const PageWorkforceLaborSummary = '/team-management/workforce/labor-summary';
export const PageWorkforceTipSummary = '/team-management/workforce/tips';
export const PageUserActivities = '/team-management/workforce/user-activities';
export const PageRoleAdd = '/team-management/role/add';
export const PageRoleEdit = '/team-management/role/edit/:id';
export const PageDepartmentAdd = '/team-management/department/add';
export const PageDepartmentEdit = '/team-management/department/edit/:id';

// settings
export const PageSettings = '/settings';
export const PageWorkflowSettings = '/settings/workflow';
export const PageTipsSettings = '/settings/tips';
export const PagePrintoutsSettings = '/settings/printouts';
export const PageReportsSettings = '/settings/reports';
export const PageTimecards = '/timecards';
export const PageTransaction = '/transactions';
export const PageTransactionOthers = '/transactions/others';
export const PageLoyalty = '/loyalty';
export const PageLoyaltyOverview = '/loyalty/overview';
export const PageLoyaltyMembers = '/loyalty/members';
export const PageLoyaltyTranscations = '/loyalty/transactions';
export const PageLoyaltySettings = '/loyalty/settings';

// smart restaurant settings
export const PageSmartRestaurantSettings = '/smart-restaurant-settings';
export const PageGeneralSettings = '/settings/general';
export const PageCDSSettings = '/settings/cds';
export const PageQRCodeSettings = '/settings/qrcode';
export const PageWaitListSettings = '/settings/waitlist';
export const PageReservationSettings = '/settings/reservation';
export const PageOnlineOrderingSettings = '/settings/online-ordering';
export const PageOnlineFee = '/settings/online-ordering/fee-and-tips';
export const PageOnlineDisplay = '/settings/online-ordering/display';
export const PageOnlineGeneral = '/settings/online-ordering/general';
export const PageCFDSettings = '/settings/cfd';

// Advertising & Benefits
export const PageLoyaltyAdvertisingAndBenefits = '/loyalty/advertising-benefits';
export const PageLoyaltyAdvertisingAndBenefitsAdvertising = '/loyalty/advertising-benefits/advertising';
export const PageLoyaltyAdvertisingAndBenefitsBenefits = '/loyalty/advertising-benefits/benefits';
export const PageLoyaltyPointsSettings = '/loyalty/points-settings';
export const PageLoyaltyPointsReport = '/loyalty/points-report';
export const PageLoyaltyAutopilot = '/loyalty/campaign/autopilot';
export const PageLoyaltyCampaign = '/loyalty/campaign';
export const PageLoyaltyCampaignAutopilot = '/loyalty/campaign/autopilot';
export const PageLoyaltyAutopilotEdit = '/loyalty/campaign/autopilot/edit';
export const PageLoyaltyManualCampaign = '/loyalty/campaign/manual-campaign';
export const PageLoyaltyManualCampaignAdd = '/loyalty/campaign/manual-campaign/add';
export const PageLoyaltyAccount = '/loyalty/campaign/account';
export const PageLoyaltyManualCampaignEdit = '/loyalty/campaign/manual-campaign/edit';
export const PageMonthlyStatement = '/reports/monthly';
export const PageGiftCard = '/reports/gift-card';
export const PageTipsAllocation = '/team-management/tips-allocation';
export const PageTipsAllocationSettings = '/team-management/tips-allocation/settings';
export const PageTipsPolicyAdd = '/team-management/tips-allocation/add';
export const PageTipsPolicyEdit = '/team-management/tips-allocation/edit/:id';
export const PageRestaurantActivate = '/user/activate';
export const PagePromotions = '/promotion-management/promotions';
export const PagePromotionAdd = '/promotion-management/promotions/add';
export const PagePromotionEdit = '/promotion-management/promotions/edit/:id';
export const PagePromotionDuplicate = '/promotion-management/promotions/duplicate/:id';
export const PageUserLogin = '/user/login';
export const PageUserActivate = '/user/activate';
export const PageUserResetPassword = '/user/reset-password';
export const PageEmployeeClockout = '/team-management/employee-clockout';

export const PageGlobalPreferenceSettings = '/settings/global';

// Order Status Board
export const PageOrderStatusBoard = '/order-status-board';
export const PageOrderStatusBoardLogin = '/order-status-board/login';
