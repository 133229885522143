import { SEGMENT_SALES, SEGMENT_OVERVIEW } from '../components/Reports/const';

const PATHS = {
  earningReport: '/restaurant-group/reports/earnings',
  account: '/restaurant-group/account',
  loyaltyPointsReport: '/restaurant-group/reports/loyalty-points',
  loyaltyMembersReport: '/restaurant-group/reports/loyalty-members',
};
export const rgPermissionCodes = {
  [PATHS.earningReport]: 'monolith:rg_earning_report:read',
  [PATHS.account]: 'monolith:rg_account:manage',
  [PATHS.loyaltyPointsReport]: 'monolith:rg_loyalty_points_report:read',
  [PATHS.loyaltyMembersReport]: 'monolith:rg_loyalty_members_report:read',
};

export const RESTAURANTS_PERMISSION = 'readAllRestaurants';

export const rgActionPermissionCodes = {
  [SEGMENT_OVERVIEW]: 'monolith:rg_sales_overview:read',
  [SEGMENT_SALES]: 'monolith:rg_sales_details:read',
  [RESTAURANTS_PERMISSION]: 'monolith:rg_restaurants_all:read',
};

export const allRGPermissions = [...Object.values(rgPermissionCodes), ...Object.values(rgActionPermissionCodes)];

export const rgMenus = [
  {
    icon: 'dashboard',
    name: 'Earning & Payout Report',
    foreign_name: '收入&打款报表',
    permission_code: rgPermissionCodes[PATHS.earningReport],
    order_num: 1,
    sub_permission_tree: [],
    path: PATHS.earningReport,
    showInMenu: true,
    children: [],
  },
  {
    icon: 'hdd',
    name: 'Loyalty Points Report',
    foreign_name: '会员积分获取消费报表',
    permission_code: rgPermissionCodes[PATHS.loyaltyPointsReport],
    order_num: 1,
    sub_permission_tree: [],
    path: PATHS.loyaltyPointsReport,
    showInMenu: true,
    children: [],
  },
  {
    icon: 'robot',
    name: 'Loyalty Members Report',
    foreign_name: '会员报表',
    permission_code: rgPermissionCodes[PATHS.loyaltyMembersReport],
    order_num: 1,
    sub_permission_tree: [],
    path: PATHS.loyaltyMembersReport,
    showInMenu: true,
    children: [],
  },

  {
    foreign_name: '账号',
    name: 'Account',
    order_num: 11,
    permission_code: rgPermissionCodes[PATHS.account],
    sub_permission_tree: [],
    icon: 'user',
    path: PATHS.account,
    showInMenu: true,
    children: [],
  },
];
