import moment from 'moment';
import { FORMAT_WITH_HHMMSS, LOCALE_EN } from '../consts';

const FORMAT = 'MM/DD/YYYY hh:mm:ss a';
const langs = {
  en: 'en-gb',
  zh: 'zh-cn',
};

export const WEEK_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export function getDateRangeDesc(query, locale = LOCALE_EN) {
  const { start_at, end_at } = query;
  if (!start_at || !end_at) return null;

  const isEN = locale === LOCALE_EN;
  const start = moment(query.start_at, FORMAT_WITH_HHMMSS).format(FORMAT);
  const end = moment(query.end_at, FORMAT_WITH_HHMMSS).format(FORMAT);
  return `${isEN ? 'Date Range' : '日期区间'}: ${start} - ${end}`;
}

export function parseMinutes(minutes) {
  let day = Math.floor(minutes / (24 * 60));
  let hour = Math.floor((minutes - day * 24 * 60) / 60);
  let min = minutes - day * 24 * 60 - hour * 60;

  return {
    day,
    hour,
    min,
  };
}

export async function updateStartOfWeekForMoment(locale = LOCALE_EN, startOfWeek) {
  const lang = langs[locale];
  await import(`moment/locale/${lang}`);
  moment.locale(lang);
  if (!startOfWeek) return;
  moment.updateLocale(lang, {
    week: {
      dow: startOfWeek === 'sunday' ? 0 : 1,
    },
  });
}
