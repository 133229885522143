const EventEmitter = {
  events: {}, // dictionary with our events
  on(event, listener) {
    // add event listeners
    if (!this.events[event]) {
      this.events[event] = { listeners: [] };
    }
    this.events[event].listeners.push(listener);
  },
  off(event, listener) {
    // remove listeners
    if (!listener) {
      delete this.events[event];
      return;
    }

    const eventObject = this.events[event];
    if (!eventObject) return;
    const { listeners } = eventObject;
    const index = listeners.indexOf(listener);
    if (index > -1) listeners.splice(index, 1);
  },
  emit(name, ...payload) {
    // trigger events
    if (!this.events[name]) {
      return;
    }
    for (const listener of this.events[name].listeners) {
      listener.apply(this, payload);
    }
  },
};

export const EVENTS = {
  // floor item
  FLOOR_ITEM_UPDATED: 'floor_item_updated',
  // admin logout event
  ADMIN_LOGOUT: 'admin_logout',
  START_DOWNLOADING_QR_CODES: 'start_downloading_qr_codes',
  END_DOWNLOADING_QR_CODES: 'end_downloading_qr_codes',
  LOADING_MONTHLY_STATEMENT_DATA: 'loading_monthly_statement_data',
  LOADED_MONTHLY_STATEMENT_DATA: 'loaded_monthly_statement_data',
  START_DOWNLOADING_MONTHLY_STATEMENT: 'start_downloading_monthly_statement',
  END_DOWNLOADED_MONTHLY_STATEMENT: 'end_downloaded_monthly_statement',
  SALES_BREAKDOWN_FILTER_CHANGED: 'sales_breakdown_filter_changed',
  TABLE_COLUMNS_CHANGED: 'table_columns_changed',
  ADD_QUICK_NOTES: 'add_quick_notes',
  ADD_ALTERNATIVE_PAYMENTS: 'add_alternative_payments',
  FLOOR_PLAN_SHAPE_SELECTION_CHANGED: 'floor_plan_shape_selection_changed',
  FLOOR_PLAN_SHAPES_MOVED: 'floor_plan_shapes_moved',
  FLOOR_PLAN_SHAPES_CHANGED: 'floor_plan_shapes_changed',
  LOADING_PAYOUT_REPORT_DATA: 'loading_payout_report_data',
  LOADED_PAYOUT_REPORT_DATA: 'loaded_payout_report_data',
  HANDLING_PHOTO: 'handling_photo',
  HANDLED_PHOTO: 'handled_photo',
  MENU_SCROLL: 'menu_scroll',
  /* menu decoration events start */
  DELETE_CURRENT_COMPONENT: 'delete_current_component',
  CREATE_CROP_BY_CONTENT: 'create_crop_by_content',
  DELETE_CROP: 'delete_crop',
  CROP_POSITION_CHANGED: 'crop_position_changed',
  TRIGGER_UPLOADING_MENU_DECORATION_IMAGE: 'trigger_uploading_menu_decoration_image',
  HOTSPOT_SELECTION_CHANGED: 'hotspot_selection_changed',
  HOTSPOT_POSITION_CALCULATED: 'hotspot_position_calculated',
  SAVE_MENU_DECORATION: 'save_menu_decoration',
  CONFIRM_SAVE_BEFORE_CHANGING_PAGE: 'confirm_save_before_changing_page',
  MENU_PAGE_BACKGROUND_IMAGE_UPLOADING: 'menu_page_background_image_uploading',
  MENU_PAGE_BACKGROUND_IMAGE_UPLOADED: 'menu_page_background_image_uploaded',
  MENU_PAGE_THUMBNAIL_IMAGE_UPLOADED: 'menu_page_thumbnail_image_uploaded',
  LOADING_MENU_DECORATION_BACKGROUND_IMAGE: 'load_menu_decoration_background_image',
  LOAD_MENU_DECORATION_BACKGROUND_IMAGE_DONE: 'load_menu_decoration_background_image_done',
  /* menu decoration events end */
  LOAD_BUFFET_LIMIT_CONFIGS_DONE: 'load_buffet_limit_configs_DONE',
  EXPAND_MENU_BUILDER_SEARCH_BAR: 'expand_menu_builder_search_bar',
  COLLAPSE_MENU_BUILDER_SEARCH_BAR: 'collapse_menu_builder_search_bar',
  REMOVE_ALIGMENT_LINES: 'remove_alignment_lines',
  /* report events start */
  SEND_VALUES_CHANGE: 'send_values_change',
  /* report events end */
  /* Order Status Board start */
  ORDER_STATUS_NOTIFICATION: 'order_status_notification',
  /* Order Status Board end */
};

export default EventEmitter;
