import { GET, POST, PUT, DELETE } from '../utils/request';
import { API_PREFIX } from '../consts';

export function queryAppConfigs({ name, authorization = '' } = {}) {
  return GET(
    `${API_PREFIX}/admin/v1/appconfig`,
    { name },
    {
      headers: {
        Authorization: (!authorization.includes('Basic') ? 'Basic ' : '') + authorization,
      },
    }
  );
}

export function createAppConfig({ name, keys, authorization = '' }) {
  return POST(
    `${API_PREFIX}/admin/v1/appconfig`,
    {
      name,
      keys,
    },
    {
      headers: {
        Authorization: (!authorization.includes('Basic') ? 'Basic ' : '') + authorization,
      },
    }
  );
}

export function updateAppConfig({ id, name, keys, authorization = '' }) {
  return PUT(
    `${API_PREFIX}/admin/v1/appconfig/${id}`,
    {
      name,
      keys,
    },
    {
      headers: {
        Authorization: (!authorization.includes('Basic') ? 'Basic ' : '') + authorization,
      },
    }
  );
}

export function deleteAppConfig({ id, authorization = '' }) {
  return DELETE(
    `${API_PREFIX}/admin/v1/appconfig/${id}`,
    {},
    {
      headers: {
        Authorization: (!authorization.includes('Basic') ? 'Basic ' : '') + authorization,
      },
    }
  );
}

export function queryClientAppConfig() {
  return GET(`${API_PREFIX}/v1/appconfig`, {}, { noNeedLogin: true });
}
