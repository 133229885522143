import { GET, POST } from '../utils/request';
import { API_PREFIX, CLIENT_ID, MONOLITH_API_PREFIX } from '../consts';
import { BILLING_REPORT_API_PREFIX } from '../consts/third_party';

export function getRestaurantGroup() {
  return GET(`${API_PREFIX}/v1/proxy/auth/api/v1/restaurateurs/restaurant_groups`);
}

export function getRestaurantList(restaurant_group_ids, restaurateur_id) {
  return GET(`${API_PREFIX}/v1/proxy/monolith/api/internal/v1/restaurant_groups/trees`, {
    restaurant_group_ids,
    restaurateur_id,
  });
}

export function switchCurrentRestaurant(restaurant_id) {
  // return POST(`${API_PREFIX}/v1/proxy/auth/api/v1/restaurateurs/switch_restaurant`, { restaurant_id });
  return POST(`${API_PREFIX}/v1/restaurant/switch`, { restaurant_id });
}

export const getRgSalesReport = ({ restaurantIds, startAt, endAt }) => {
  return POST(`${BILLING_REPORT_API_PREFIX}/possales/api/v1/earning/report`, {
    restaurant_ids: restaurantIds,
    start_date: startAt,
    end_date: endAt,
  });
};

export function queryGroupPayoutReport({ restaurantIds, startAt, endAt }) {
  return GET(
    `${MONOLITH_API_PREFIX}/api/restaurant_group/v1/restaurant_groups/group_payout_report`,
    {
      'restaurant_ids[]': restaurantIds,
      start_at: startAt,
      end_at: endAt,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: false,
    }
  );
}

export function querySalesBreakdown({ restaurantIds, startAt, endAt }) {
  return POST(
    `${BILLING_REPORT_API_PREFIX}/possales/api/v1/earning/sales-breakdown`,
    {
      restaurant_ids: restaurantIds,
      start_date: startAt,
      end_date: endAt,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: false,
    }
  );
}

export function getRestaurantGroupInfo(restaurant_id) {
  return GET(
    `${MONOLITH_API_PREFIX}/api/restaurant_group/v1/restaurant_groups`,
    {
      restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
      silence: true,
    }
  );
}

export function getRestaurantGroupDealContract(restaurant_id) {
  return GET(
    `${MONOLITH_API_PREFIX}/wallet/api/gift_card/restaurant_group/deal_contracts/query_by_use_scope`,
    {
      scope_resource_ref: restaurant_id,
      distribution_mode: 'loyalty',
      scope_resource_type: 'Restaurant',
    },
    {
      parseAsJSONApi: true,
      silence: true,
    }
  );
}

export function getRestaurantGroupGiftCardContract(restaurant_id) {
  return GET(
    `${MONOLITH_API_PREFIX}/wallet/api/gift_card/restaurant_group/deal_contracts/query_by_use_scope`,
    {
      scope_resource_ref: restaurant_id,
      distribution_mode: 'gift_card',
      scope_resource_type: 'Restaurant',
    },
    {
      parseAsJSONApi: true,
      silence: true,
    }
  );
}
