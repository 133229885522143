export function rangeNumber({ start, end, groupCount = 5 }) {
  const groups = [];
  let group;

  for (let i = start; i <= end; i++) {
    if ((i - start) % groupCount === 0) {
      group = [];
      groups.push(group);
    }
    group.push(i);
  }

  return groups;
}

export function groupList(rawList, parallelCount = 5) {
  const total = rawList.length;
  const groups = [];
  const groupCount = Math.ceil(total / parallelCount);

  for (let i = 0; i < groupCount; i++) {
    groups.push(rawList.slice(i * parallelCount, (i + 1) * parallelCount));
  }

  return groups;
}

export function numberRangeToArray(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

export function isNotEmptyArray(arr) {
  return Array.isArray(arr) && arr.length > 0;
}

// unordered, ordered
export function arraysEqual(arrayA = [], arrayB = [], { sequential = 'unordered' } = {}) {
  console.log(arrayA, arrayB);
  // 首先检查数组长度是否相同
  if (arrayA.length !== arrayB.length) return false;

  // 如果是无序比较，则使用一个映射对象来检查元素出现次数
  if (sequential === 'unordered') {
    const elementCount = new Map();

    // 遍历第一个数组，统计每个元素出现的次数
    for (const element of arrayA) {
      elementCount.set(element, (elementCount.get(element) || 0) + 1);
    }

    // 遍历第二个数组，减少元素出现的次数，如果某个元素不存在或计数为0，则返回false
    for (const element of arrayB) {
      const count = elementCount.get(element);
      if (!count || count === 0) return false;
      elementCount.set(element, count - 1);
    }

    // 检查所有元素计数是否都为0
    for (const count of elementCount.values()) {
      if (count !== 0) return false;
    }

    return true;
  }

  // 如果是有序比较，则直接比较
  return JSON.stringify(arrayA) === JSON.stringify(arrayB);
}
