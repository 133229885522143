/***
 * define route settings 定义路由设置
 * type RouteSettings = {
 *   path: string,                对应的路径
 *   icon?: string,               menu上显示的图标(只有顶级路由才显示icon)
 *   visibleChecker?: (restaurantInfo: object) => boolean,   菜单可见性判断函数 restaurantInfo 餐厅信息
 * }
 * type RoutesSettings = Record<string, RouteSettings>
 * key 为权限点的code, 类型为string, 之所以使用权限点作为key，是因为导航菜单是基于权限树，导航菜单只取了一级以及部分二级权限点
 * 如果一个页面有多个tab, 每个tab对应一个路由，每个路由对应的内容使用TabRoutePermissionMapping进行控制，在TabRoutePermissionMapping
 * 中增加路由对应的权限点(及合约distributionMode，如果需要根据合约来控制)，如为设置tab路由对应的权限点，则由上层路由控制
 */

import { DISTRIBUTION_MODES } from '.';
import {
  TEAM,
  ROLE_LIST,
  MEMBER_LIST,
  REPORTS,
  TRANSACTION,
  SETTING,
  ACCOUNT,
  MENU,
  MENU_AVAILABILITY,
  DEVICE,
  HOME,
  FLOOR_PLAN,
  ADJUSTMENT,
  DISCOUNT,
  FEE,
  MENU_PREFERENCES,
  MENU_SETUP,
  MENU_CUSTOMIZATIONS,
  SETTING_GENERAL,
  SETTING_QRCODE_ORDERING,
  SETTING_WAITLIST,
  SETTING_RESERVATION,
  REPORTS_SALES,
  REPORTS_PAYOUT_FEE,
  WORKFORCE,
  MENU_PRICE_EDITOR,
  LOYALTY_REPORTS,
  LOYALTY_MEMBERS,
  LOYALTY_TRANSACTIONS,
  LOYALTY_SETTINGS,
  LOYALTY_ADVERTISINGANDBENEFITS,
  LOYALTY_ADVERTISINGANDBENEFITS_ADVERTISING,
  LOYALTY_ADVERTISINGANDBENEFITS_BENEFITS,
  LOYALTY_POINTS,
  LOYALTY_POINTS_REPORTS,
  REPORTS_MONTHLY,
  REPORTS_VOIDED_ITEMS,
  REPORTS_CASH,
  SETTING_SERVICE,
  CATEGORY,
  SETTING_TIPS,
  SETTING_PRINTOUTS,
  REPORTS_PAYOUT,
  DEPARTMENT_LIST,
  REPORTS_GIFT_CARD,
  ROLES_AND_MEMBERS,
  LOYALTY,
  WORKFORCE_LABOR_SUMMARY,
  WORKFORCE_PRODUCTIVITY,
  WORKFORCE_TIME_LOG,
  TIPS_MANAGEMENT,
  LOYALTY_AUTOPOLIT,
  LOYALTY_MANUAL_CAMPAIGN,
  MENU_PRINTER_MAPPING,
  SETTING_REPORTS,
  PROMOTIONS,
  ORDERS_AND_SHOPPING,
  SETTING_ONLINE_ORDERING,
  SALES_CATEGORY,
  SETTING_GLOBAL,
  SETTING_SMART_RESTAURANT,
  SETTING_CDS,
  ORDERS,
  SHOPPING_CART,
  LOYALTY_CAMPAIGN,
  LOYALTY_ACCOUNT,
  BREAK_MANAGEMENT,
  EMPLOYEE_CLOCKOUT,
  SETTING_CFD,
} from './permissionCodes';
import {
  PageAccount,
  PageAdjustment,
  PageCashReport,
  PageDashboard,
  PageDevice,
  PageDiscount,
  PageFee,
  PageFloorPlan,
  PageGeneralSettings,
  PageLoyaltyMembers,
  PageLoyaltyTranscations,
  PageLoyaltyOverview,
  PageLoyaltySettings,
  PageLoyaltyAdvertisingAndBenefits,
  PageLoyaltyAdvertisingAndBenefitsAdvertising,
  PageLoyaltyAdvertisingAndBenefitsBenefits,
  PageLoyaltyPointsReport,
  PageLoyaltyPointsSettings,
  PageLoyaltyCampaignAutopilot,
  PageLoyaltyManualCampaign,
  PageMenuManagement,
  PageMenuManagementForAvailability,
  PageMenuManagementForCustomizations,
  PageMenuManagementForDishPreference,
  PageMenuManagementForMenus,
  PageMenuManagementForPriceEditor,
  PageMonthlyStatement,
  PageOrders,
  PageWorkforce,
  PageQRCodeSettings,
  PageWaitListSettings,
  PageReservationSettings,
  PageReport,
  PageSalesReport,
  PageSettings,
  PageTeam,
  PageTeamMembers,
  PageTeamRoles,
  PageTransaction,
  PageWorkflowSettings,
  PageMenuManagementForCategories,
  PageTipsSettings,
  PagePrintoutsSettings,
  PagePayoutReport,
  PagePayoutFeeReport,
  PageTeamDepartment,
  PageGiftCard,
  PageTeamRolesAndMembers,
  PageLoyalty,
  PageWorkforceLaborSummary,
  PageWorkforceProductivity,
  PageWorkforceTimeLog,
  PageTipsAllocation,
  PageWorkforceTipSummary,
  PageUserActivities,
  PageMenuManagementPrinterMapping,
  PageReportsSettings,
  PagePromotions,
  PageOnlineOrderingSettings,
  PageMenuManagementForSalesCategory,
  PageGlobalPreferenceSettings,
  PageCDSSettings,
  PageSmartRestaurantSettings,
  PageClosedOrders,
  PageOpenOrders,
  PageLoyaltyCampaign,
  PageLoyaltyAccount,
  PageTeamBreaks,
  PageEmployeeClockout,
  PageCFDSettings,
} from './routes';

// home / 主页
const HomeRoutePermission = {
  [HOME]: {
    icon: 'home',
    path: PageDashboard,
  },
};

// orders / 订单
const OrdersRoutesPermissions = {
  [ORDERS_AND_SHOPPING]: {
    path: PageOrders,
    icon: 'shopping',
  },
};
// 订单页面tab route mapping
const OrderTabRoutePermissionMapping = {
  [PageClosedOrders]: {
    permissionCode: ORDERS,
  },
  [PageOpenOrders]: {
    permissionCode: SHOPPING_CART,
  },
};

// transaction / 交易
const TransactionRoutesPermissions = {
  [TRANSACTION]: {
    path: PageTransaction,
    icon: 'transaction',
  },
};

// menu management / 菜单管理
const MenuRoutesPermissions = {
  [MENU]: {
    path: PageMenuManagement,
    icon: 'profile',
  },
  [MENU_AVAILABILITY]: {
    path: PageMenuManagementForAvailability,
  },
  [CATEGORY]: {
    path: PageMenuManagementForCategories,
  },
  [MENU_PRICE_EDITOR]: {
    path: PageMenuManagementForPriceEditor,
  },
  [MENU_PREFERENCES]: {
    path: PageMenuManagementForDishPreference,
  },
  [MENU_SETUP]: {
    path: PageMenuManagementForMenus,
  },
  [MENU_CUSTOMIZATIONS]: {
    path: PageMenuManagementForCustomizations,
  },
  [MENU_PRINTER_MAPPING]: {
    path: PageMenuManagementPrinterMapping,
  },
  [SALES_CATEGORY]: {
    path: PageMenuManagementForSalesCategory,
  },
};

// promotion management / 促销管理
const PromotionRoutesPermissions = {
  [PROMOTIONS]: {
    icon: 'gift',
    path: PagePromotions,
    distributionMode: DISTRIBUTION_MODES.PROMOTION,
  },
};

// adjustment management / 金额调整管理
const AdjustmentRoutesPermissions = {
  [ADJUSTMENT]: {
    path: PageAdjustment,
    icon: 'money-collect',
  },
};
// 金额调整页面tab route permission mapping
const AdjustmentTabRoutePermissionMapping = {
  [PageDiscount]: {
    permissionCode: DISCOUNT,
  },
  [PageFee]: {
    permissionCode: FEE,
  },
};

// team management / 团队管理
const TeamRoutesPermissions = {
  [TEAM]: {
    path: PageTeam,
    icon: 'team',
  },
  [ROLES_AND_MEMBERS]: {
    path: PageTeamRolesAndMembers,
  },
  [WORKFORCE]: {
    path: PageWorkforce,
  },
  [BREAK_MANAGEMENT]: {
    path: PageTeamBreaks,
  },
  [TIPS_MANAGEMENT]: {
    path: PageTipsAllocation,
  },
  [EMPLOYEE_CLOCKOUT]: {
    path: PageEmployeeClockout,
  },
};
// 团队管理Tab Route permission mapping
const TeamTabRoutePermissionMapping = {
  [PageTeamRoles]: {
    permissionCode: ROLE_LIST,
  },
  [PageTeamMembers]: {
    permissionCode: MEMBER_LIST,
  },
  [PageTeamDepartment]: {
    permissionCode: DEPARTMENT_LIST,
  },
  [PageWorkforceProductivity]: {
    permissionCode: WORKFORCE_PRODUCTIVITY,
  },
  [PageWorkforceTimeLog]: {
    permissionCode: WORKFORCE_TIME_LOG,
  },
  [PageWorkforceLaborSummary]: {
    permissionCode: WORKFORCE_LABOR_SUMMARY,
  },
  [WORKFORCE_LABOR_SUMMARY]: {
    permissionCode: PageWorkforceLaborSummary,
  },
  [PageUserActivities]: {
    permissionCode: REPORTS_VOIDED_ITEMS,
  },
  [PageWorkforceTipSummary]: {
    permissionCode: TIPS_MANAGEMENT,
  },
};

// Device / 设备
const DeviceRoutesPermissions = {
  [DEVICE]: {
    path: PageDevice,
    icon: 'desktop',
  },
};

// account / 账号
const AccountRoutesPermissions = {
  [ACCOUNT]: {
    path: PageAccount,
    icon: 'user',
  },
};

// settings / 设置
const SettingsRoutesPermissions = {
  [SETTING]: {
    path: PageSettings,
    icon: 'setting',
  },
  [SETTING_GLOBAL]: {
    path: PageGlobalPreferenceSettings,
  },
  [SETTING_GENERAL]: {
    path: PageGeneralSettings,
  },
  [SETTING_QRCODE_ORDERING]: {
    path: PageQRCodeSettings,
    distributionMode: DISTRIBUTION_MODES.SMART_ORDERING,
  },
  [SETTING_SERVICE]: {
    path: PageWorkflowSettings,
  },
  [SETTING_TIPS]: {
    path: PageTipsSettings,
  },
  [SETTING_PRINTOUTS]: {
    path: PagePrintoutsSettings,
  },
  [SETTING_REPORTS]: {
    path: PageReportsSettings,
  },
  [SETTING_ONLINE_ORDERING]: {
    path: PageOnlineOrderingSettings,
    visibleChecker: (restaurantInfo) => {
      const { restaurant_contracts } = restaurantInfo;
      // 判断是否开了white_label合约，并且为v2
      return (restaurant_contracts || []).some(
        (item) => item['online_ordering_version'] === 'v2' && item.distribution_mode === DISTRIBUTION_MODES.WHITE_LABEL
      );
    },
  },
  [SETTING_SMART_RESTAURANT]: {
    icon: 'smart-restaurant',
    path: PageSmartRestaurantSettings,
  },
  [SETTING_CDS]: {
    path: PageCDSSettings,
  },
  [SETTING_CFD]: {
    path: PageCFDSettings,
  },
  [SETTING]: {
    path: PageSettings,
    icon: 'setting',
  },
  [SETTING_WAITLIST]: {
    path: PageWaitListSettings,
    distributionMode: DISTRIBUTION_MODES.WAIT_LIST,
  },
  [SETTING_RESERVATION]: {
    path: PageReservationSettings,
    distributionMode: DISTRIBUTION_MODES.RESERVATION,
  },
};

// Reports / 报表
const ReportRoutesPermissions = {
  [REPORTS]: {
    path: PageReport,
    icon: 'dashboard',
  },
  [REPORTS_SALES]: {
    path: PageSalesReport,
  },
  [REPORTS_PAYOUT]: {
    path: PagePayoutReport,
    visibleChecker: (restaurantInfo) => {
      const { restaurant_contracts } = restaurantInfo;
      // 判断pos contract 的cost_plus_start_at 字段是否不为空
      return (
        (restaurant_contracts || []).findIndex(
          (item) => item.distribution_mode === DISTRIBUTION_MODES.POS && !!item.cost_plus_start_at
        ) > -1
      );
    },
  },
  [REPORTS_PAYOUT_FEE]: {
    path: PagePayoutFeeReport,
  },
  [REPORTS_MONTHLY]: {
    path: PageMonthlyStatement,
  },
  [REPORTS_CASH]: {
    path: PageCashReport,
  },
  [REPORTS_GIFT_CARD]: {
    path: PageGiftCard,
  },
};

// floor plan / 平面图
const FloorPlanRoutesPermissions = {
  [FLOOR_PLAN]: {
    icon: 'layout',
    path: PageFloorPlan,
  },
};

// loyalty / 会员系统
const LoyaltyRoutesPermissions = {
  [LOYALTY]: {
    icon: 'apartment',
    path: PageLoyalty,
    distributionMode: DISTRIBUTION_MODES.LOYALTY,
  },
  [LOYALTY_REPORTS]: {
    path: PageLoyaltyOverview,
  },
  [LOYALTY_MEMBERS]: {
    path: PageLoyaltyMembers,
  },
  [LOYALTY_TRANSACTIONS]: {
    path: PageLoyaltyTranscations,
  },
  [LOYALTY_SETTINGS]: {
    path: PageLoyaltySettings,
  },

  //Advertising & Benefits
  [LOYALTY_ADVERTISINGANDBENEFITS]: {
    path: PageLoyaltyAdvertisingAndBenefits,
  },
  [LOYALTY_POINTS]: {
    path: PageLoyaltyPointsSettings,
  },
  [LOYALTY_POINTS_REPORTS]: {
    path: PageLoyaltyPointsReport,
  },
  [LOYALTY_CAMPAIGN]: {
    path: PageLoyaltyCampaign,
    distributionMode: DISTRIBUTION_MODES.LOYALTY_CRM,
  },
  [LOYALTY_AUTOPOLIT]: {
    path: PageLoyaltyCampaign,
  },
  [LOYALTY_ACCOUNT]: {
    path: PageLoyaltyAccount,
  },
};

// 会员系统tab route permission mapping
const LoyaltyTabRoutePermissionMapping = {
  [PageLoyaltyAdvertisingAndBenefitsAdvertising]: {
    permissionCode: LOYALTY_ADVERTISINGANDBENEFITS_ADVERTISING,
    distributionMode: DISTRIBUTION_MODES.LOYALTY,
  },
  [PageLoyaltyAdvertisingAndBenefitsBenefits]: {
    permissionCode: LOYALTY_ADVERTISINGANDBENEFITS_BENEFITS,
    distributionMode: DISTRIBUTION_MODES.LOYALTY,
  },
  [PageLoyaltyCampaignAutopilot]: {
    permissionCode: LOYALTY_AUTOPOLIT,
    distributionMode: DISTRIBUTION_MODES.LOYALTY_CRM,
  },
  [PageLoyaltyManualCampaign]: {
    permissionCode: LOYALTY_MANUAL_CAMPAIGN,
    distributionMode: DISTRIBUTION_MODES.LOYALTY_CRM,
  },
  [PageLoyaltyAccount]: {
    permissionCode: LOYALTY_ACCOUNT,
    distributionMode: DISTRIBUTION_MODES.LOYALTY,
  },
};

// nav menu permission mapping
export default {
  ...HomeRoutePermission,
  ...OrdersRoutesPermissions,
  ...TransactionRoutesPermissions,
  ...MenuRoutesPermissions,
  ...PromotionRoutesPermissions,
  ...AdjustmentRoutesPermissions,
  ...TeamRoutesPermissions,
  ...DeviceRoutesPermissions,
  ...AccountRoutesPermissions,
  ...SettingsRoutesPermissions,
  ...ReportRoutesPermissions,
  ...FloorPlanRoutesPermissions,
  ...LoyaltyRoutesPermissions,
};

export const TabRoutePermissionMapping = {
  ...OrderTabRoutePermissionMapping,
  ...AdjustmentTabRoutePermissionMapping,
  ...TeamTabRoutePermissionMapping,
  ...LoyaltyTabRoutePermissionMapping,
};
