import router from 'next/router';
import { getAuthorToken } from '../services/login';
import { querySystemPermissionTree, queryUserPermissionCodes } from '../services/permission';
import { queryRestaurantEmployeeDetail } from '../services/employee';
import routeMapping from '../consts/routeMapping';
import { HOME } from '../consts/permissionCodes';
import {
  setSessionItem,
  KEYS,
  USER_KEYS,
  getSessionItem,
  clearSession,
  setCookie,
  deleteAllCookies,
} from '../store/storage';
import { extractRestaurantId } from '../utils/user';
import { mixpanel } from '../third_party/Mixpanel';
import { unsubscribeRestaurantChannel } from '../third_party/pusher';
import requestManager from '../utils/requestManager';
import { PageDashboard, PageOrderStatusBoard, PageOrderStatusBoardLogin } from '../consts/routes';
import { clearAppSessions, setIsGroup } from './app';
import { clearRgAllCachedData, rgLogout, rgLogin } from './rgUser';
import { setAlternativePayments } from './alternativePayments';
import { getFeatureConfig } from 'src/services/commonConfig';

const BASE = 'USER';

export const actions = {
  login: `${BASE}_LOGIN`,
  loginSuccess: `${BASE}_LOGIN_SUCCESS`,
  loginFailed: `${BASE}_LOGIN_FAILED`,
  refreshingToken: `${BASE}_REFRESHING_TOKEN`,
  refreshTokenSuccess: `${BASE}_REFRESH_TOKEN_SUCCESS`,
  refreshTokenFailed: `${BASE}_REFRESH_TOKEN_FAILED`,
  loadingPermissionCodes: `${BASE}_LOADING_PERMISSION_CODES`,
  loadPermissionCodesSuccess: `${BASE}_LOAD_PERMISSION_CODES_SUCCESS`,
  loadPermissionCodesFailed: `${BASE}_LOAD_PERMISSION_CODES_FAILED`,
  loadingMenus: `${BASE}_LOADING_MENUS`,
  loadingMenuSuccess: `${BASE}_LOADING_MENUS_SUCCESS`,
  loadingMenuFailed: `${BASE}_LOADING_MENUS_FAILED`,
  loadingOwnerInfo: `${BASE}_LOADING_OWNER_INFO`,
  loadOwnerInfoSuccess: `${BASE}_LOAD_OWNER_INFO_SUCCESS`,
  loadOwnerInfoFailed: `${BASE}_LOAD_OWNER_INFO_FAILED`,
  resetReduxData: `${BASE}_RESET_REDUX_DATA`,
  cacheUserInfo: `${BASE}_CACHE_USER_INFO`,
  clearAllCachedData: `${BASE}_CLEAR_CACHED_DATA`,
  clearMenusPermissions: `${BASE}_CLEAR_MENU_AND_PERMISSION_CODES`,
  cacheEmployeeInfo: `${BASE}_CACHE_EMPLOYEE_INFO`,
  loadOwnedRestaurantsSuccess: `${BASE}_LOAD_OWNED_RESTAURNTS_SUCCESS`,
  loadOwnedRestaurantsFailed: `${BASE}_LOAD_OWNED_RESTAURNTS_FAILED`,
  loadRestaurantGroupInfo: `${BASE}_LOAD_RESTAURANT_GROUP_IFNO`,
  loadRestaurantGroupInforSucess: `${BASE}_LOAD_RESTAURANT_GROUP_IFNO_SUCESS`,
  loadRestaurantGroupInforFailed: `${BASE}_LOAD_RESTAURANT_GROUP_IFNO_FAILED`,
  updateFeatureFlagState: `${BASE}_UPDATE_FEATURE_FALG_STATE`,
};

const loginPath = '/user/login';
const internalPagePrefix = '/internal';

export const noNeedAuthPaths = (pathName) => {
  const exceptionList = ['/receipt', loginPath, '/landing'];
  return exceptionList.find((item) => item === pathName);
};

export const handleAuthorData = (data) => {
  const { access_token, refresh_token, expires_in = 1800, email = '' } = data;
  if (!access_token) return;
  const { restaurantId, identity } = extractRestaurantId(data);
  const realExpiresIn = expires_in - 30;
  const expireTime = new Date().getTime() + realExpiresIn * 1000;
  setCookie({
    [KEYS.token]: access_token,
    [KEYS.refreshToken]: refresh_token,
    [KEYS.email]: email,
  });
  setSessionItem({
    [KEYS.token]: access_token,
    [KEYS.refreshToken]: refresh_token,
    [KEYS.expireTime]: expireTime,
    [KEYS.email]: email,
    [KEYS.restaurantId]: restaurantId,
    [KEYS.userInfo]: data,
    [KEYS.identity]: identity,
  });
  return { user: data, restaurantId, identity };
};

const redirectToFromUrl =
  (newRestaurantId = '') =>
  async () => {
    const {
      pathname,
      query: { from = PageDashboard, restaurantId },
    } = router;
    let jumpPath = from;
    if (pathname.indexOf(PageOrderStatusBoard) > -1) {
      jumpPath = PageOrderStatusBoard;
    } else if (restaurantId !== String(newRestaurantId)) {
      jumpPath = PageDashboard;
    }
    router.replace(jumpPath);
  };

export const loginByPassword = (params, callback) => async () => {
  let response;

  try {
    response = await getAuthorToken(params);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);
};

export const handleSessionData = (payload) => async (dispatch, getState) => {
  const { user, restaurantId, identity } = handleAuthorData(payload);
  const currentRestaurantId = getState().user?.currentRestaurantId;
  if (restaurantId !== currentRestaurantId) {
    dispatch({
      type: actions.resetReduxData,
    });
  }
  dispatch(
    cacheCurrentUserInfo({
      currentUser: user,
      currentRestaurantId: restaurantId,
      email: user.email,
      identity,
    })
  );
  mixpanel.updateUser();
};

const handleLoginResponse = (response) => async (dispatch) => {
  if (!response.success) {
    dispatch({
      type: actions.loginFailed,
    });
    return;
  }
  const data = response.data || {};
  dispatch(handleSessionData(data));
  dispatch({ type: actions.loginSuccess });
  dispatch(setIsGroup(false));
  const { restaurantId } = handleAuthorData(data);
  dispatch(redirectToFromUrl(restaurantId));
};

export const restlogin = (params) => async (dispatch) => {
  dispatch({
    type: actions.login,
  });

  dispatch(
    loginByPassword(params, (response) => {
      dispatch(handleLoginResponse(response));
    })
  );
};

export const login = (params) => async (dispatch) => {
  dispatch({ type: actions.clearMenusPermissions });

  const { email, password, identity } = params;

  if (identity === 'restaurant') {
    dispatch(
      restlogin({
        email,
        password,
      })
    );
  } else {
    dispatch(
      rgLogin({
        email,
        password,
      })
    );
  }
};

export const clearUserSession = () => async (dispatch) => {
  dispatch(clearAllCachedData());
  dispatch(clearRgAllCachedData());
  clearSession();
  deleteAllCookies();
};

export const restLogout = () => async (dispatch) => {
  if (noNeedAuthPaths(router.pathname) || router.pathname.includes(internalPagePrefix)) return;
  const restaurantId = getSessionItem(USER_KEYS.restaurantId);
  requestManager.cancelAll();
  dispatch(clearUserSession());
  dispatch(clearAppSessions());
  setAlternativePayments([]);
  unsubscribeRestaurantChannel(restaurantId);
  router.replace({
    pathname: router.pathname.includes(PageOrderStatusBoard) ? PageOrderStatusBoardLogin : loginPath,
    query: {
      from: router.asPath,
      restaurantId,
    },
  });
};

export const logout = () => async (dispatch, getState) => {
  const { app } = getState();
  if (app?.isGroupUser) {
    await dispatch(rgLogout());
  } else {
    await dispatch(restLogout());
  }
};

function convertMenuData({ menus, menusData = [], sortBySequence = true, depth = 1 }) {
  menus
    .sort((a, b) => {
      if (sortBySequence) return a.order_num - b.order_num;
      return (a.name || '').localeCompare(b.name || '');
    })
    .forEach((menu) => {
      const { permission_code, permission_type } = menu;
      const routeSetting = routeMapping[permission_code];
      if (!routeSetting || permission_type === 3 || depth > 2) return;

      const menuItem = {
        ...menu,
        ...routeSetting,
        children: [],
      };
      menusData.push(menuItem);

      if (menuItem.sub_permission_tree && menuItem.sub_permission_tree.length) {
        convertMenuData({
          menus: menuItem.sub_permission_tree,
          menusData: menuItem.children,
          sortBySequence,
          depth: depth + 1,
        });
      }
    });

  return menusData;
}

export const loadMenus =
  (refresh = false) =>
  async (dispatch, getState) => {
    if (!refresh) {
      const { menus } = getState().user;
      if (menus.length) return;
    }

    dispatch({
      type: actions.loadingMenus,
    });

    let response;

    try {
      response = await querySystemPermissionTree();
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      dispatch({
        type: actions.loadingMenuFailed,
      });
      return;
    }

    const rawData = response.data || [];
    rawData.unshift({
      name: 'Home',
      foreign_name: '主页',
      permission_code: HOME,
      order_num: 0,
    });
    const menus = convertMenuData({
      menus: response.data || [],
      menusData: [],
      sortBySequence: true,
      depth: 1,
    });
    dispatch({
      type: actions.loadingMenuSuccess,
      payload: menus,
    });
  };

export const loadPermissionCodes =
  (refresh = false) =>
  async (dispatch, getState) => {
    const useStore = getState().user;

    if (!refresh && useStore.isLoadPermissionCodeDone) return;
    if (useStore.isLoadingPermissionCode) return;

    dispatch({
      type: actions.loadingPermissionCodes,
    });

    let response;

    try {
      response = await queryUserPermissionCodes();
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      dispatch({
        type: actions.loadPermissionCodesFailed,
      });
      return;
    }

    const userPermissions = (response.data || {}).permission_codes || [];

    dispatch({
      type: actions.loadPermissionCodesSuccess,
      payload: userPermissions,
    });
  };

export const loadOwnerInfo = (owner_id) => async (dispatch, getState) => {
  const { isLoadOwnerInfoSuccess } = getState().user;
  if (isLoadOwnerInfoSuccess) return;
  dispatch({
    type: actions.loadingOwnerInfo,
  });

  let response;

  try {
    response = await queryRestaurantEmployeeDetail(owner_id);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadOwnerInfoFailed,
    });
    return;
  }

  dispatch({
    type: actions.loadOwnerInfoSuccess,
    payload: response.data || {},
  });
};

export const clearAllCachedData = () => async (dispatch) => {
  dispatch({ type: actions.clearAllCachedData });
};

export const cacheCurrentUserInfo = (payload) => async (dispatch) => {
  dispatch({
    type: actions.cacheUserInfo,
    payload,
  });
};

export const fetchCurrentEmployeeInfo = (userId) => async (dispatch, getState) => {
  const { currentEmployee } = getState().user;
  if (currentEmployee) return;

  let response;

  try {
    response = await queryRestaurantEmployeeDetail({ userId });
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return;

  const employee = response.data || {};
  setSessionItem({ [USER_KEYS.ownerId]: employee.owner_id });

  const payload = {
    currentEmployee: employee,
  };

  if (employee.id === employee.owner_id) {
    payload.ownerInfo = employee;
  }

  dispatch({
    type: actions.cacheEmployeeInfo,
    payload,
  });
};

/**
 * @param params {object}
 * @param params.featureName feature flag名称
 * @param params.shouldCache 是否需要缓存检测结果
 * @param params.detectCallback 检测后的回调方法
 */
export const fetchFeatureFlagState =
  ({ featureName, shouldCache = false, detectCallback }) =>
  async (dispatch, getState) => {
    const isFunction = typeof detectCallback === 'function';
    const { featureEnabledState } = getState().user;

    if (shouldCache) {
      const featureEnabled = featureEnabledState?.[featureName];
      if (featureEnabled !== undefined) {
        if (isFunction) detectCallback(featureEnabled);
        return;
      }
    }

    let response;

    try {
      response = await getFeatureConfig(featureName);
    } catch (e) {
      response = { success: false };
    }

    let enabled = false;
    if (response.success) {
      enabled = response.data.switch;
    }

    if (shouldCache) {
      const newFeatureEnabledState = Object.create(featureEnabledState || {});
      newFeatureEnabledState[featureName] = enabled;
      dispatch({
        type: actions.updateFeatureFlagState,
        payload: newFeatureEnabledState,
      });
    }

    if (isFunction) detectCallback(enabled);
  };
