export const FIELD_NET_REFUNDS = 'net_refunds';
export const FIELD_REFUNDS = 'refunds';

export const salesColumns = [
  'gross_sales',
  'discounts',
  'net_sales',
  'bill_count',
  'dish_count',
  'fees_collected',
  'taxes',
  'tips',
  'driver_tips',
  FIELD_REFUNDS,
  FIELD_NET_REFUNDS,
  'commission',
  'adjustments',
  'amount_collected',
  'open_order_count',
  'canceled_order_count',
  'net_cash',
  'amount_receivable',
];

export const salesByCategoryNumberColumns = ['amount', 'discount', 'net_sales', 'order_count', 'quantity'];
export const salesByDishNumberColumns = ['bill_count', 'gross_sales'];
export const tipsColumns = ['card_tips', 'cash_tips', 'other_tips', 'tips_refund', 'tips'];
export const salesTrendChartLegends = ['gross_sales', 'net_sales'];
export const SEGMENT_OVERVIEW = 'overview';
export const SEGMENT_SALES = 'sales';
export const SEGMENT_SALES_BREAKDOWN = 'sales_category';
export const SEGMENT_SERVICE_PERIOD = 'service_period';

export const SEGMENT_TABS = [SEGMENT_OVERVIEW, SEGMENT_SALES, SEGMENT_SALES_BREAKDOWN, SEGMENT_SERVICE_PERIOD];

// sales report new structure
export const salesSummaryColumns = [
  'amount_receivable',
  'gross_sales',
  'discounts',
  'net_sales',
  'fees',
  'taxes',
  'staff_tips',
  'driver_tips',
  'deferred_sales',
  'commissions',
  'adjustments',
  'voids',
  'comps',
  'refunds',
  'guests',
];
export const excludeRefundColumns = [
  'amount_receivable',
  'gross_sales',
  'discounts',
  'adjustments',
  'refunds',
  'voids',
];
export const countColumns = ['bill_count', 'dish_count', 'open_order_count', 'canceled_order_count', 'net_cash'];
export const statisticColumns = ['net_cash'];
export const breakdownFields = ['fees', 'staff_tips', 'deferred_sales'];
export const inHouseNegativeColumns = ['discounts', 'comps', 'refunds'];
export const onlineNegativeColumns = ['discounts', 'refunds'];
export const extraRefundFields = ['payment_amount', 'reopen_amount'];

// all restaurants activated daily cost plus after 2024-03-01
export const dailytCostPlusDefaultDate = '2024-03-01';
export const costplus_adjustment_type = 'costplus_adjustment';

export const tipsSummaryColumns = ['staff_tips', 'driver_tips'];
export const FIELD_TIPS = 'tips';
