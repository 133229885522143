import { GET, POST, PUT, DELETE } from '../utils/request';
import { API_PREFIX } from '../consts';

export function queryRestaurantEmployees() {
  return GET(`${API_PREFIX}/v1/employees`);
}

export function queryMembersByRoleId(roleId) {
  return GET(`${API_PREFIX}/v1/employees/role/${roleId}`);
}

export function queryRestaurantEmployeeDetail({ userId }) {
  return GET(`${API_PREFIX}/v1/employee/${userId}`);
}

export function createRestaurantEmployee(data) {
  return POST(`${API_PREFIX}/v1/employee`, data);
}

export function updateRestaurantEmployee({ id, ...data }) {
  return PUT(`${API_PREFIX}/v1/employee/${id}`, data);
}

export function deleteEmployee(userId) {
  return DELETE(`${API_PREFIX}/v1/employee/${userId}`);
}

export function activateRestaurantEmployee({ id, ...data }) {
  return PUT(`${API_PREFIX}/v1/employee/${id}/activate`, data);
}

export function deactivateRestaurantEmployee(userId) {
  return PUT(`${API_PREFIX}/v1/employee/${userId}/deactivate`);
}

export function getEmployeesByDepartmentId(departmentId) {
  return GET(`${API_PREFIX}/v1/employees/department/${departmentId}`);
}

export function getUnassignedDepartmentEmployees() {
  return GET(`${API_PREFIX}/v1/employees/department/unassign`);
}

export function getOwnedRestaruants(userId) {
  return GET(`${API_PREFIX}/v1/employee/${userId}/restaurants`);
}
