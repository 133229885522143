import {
  batchUpdateAlternativePayments,
  createAlternativePayments,
  deleteAlternativePayments,
  getAlternativePayments,
} from '../services/alternativePayments';

const BASE = 'ALTERNATIVE_PAYMENTS';

export const actions = {
  loadingAlternativePayments: `${BASE}_LOADING_ALTERNATIVE_PAYMENTS`,
  loadedAlternativePayments: `${BASE}_LOADED_ALTERNATIVE_PAYMENTS`,
};

export const setAlternativePayments = (payments) => async (dispatch) => {
  dispatch({
    type: actions.loadedAlternativePayments,
    payload: payments,
  });
};

export const queryAlternativePayments = (callback) => async (dispatch, getState) => {
  let response;

  const {
    alternativePayments: { alternativePayments },
  } = getState();

  if (alternativePayments.length) return;

  dispatch({ type: actions.loadingAlternativePayments });

  try {
    response = await getAlternativePayments();
  } catch (e) {
    response = { success: false };
  }

  const payments = response.success ? response.data || [] : [];
  payments.sort((a, b) => a.sequence - b.sequence);
  dispatch(setAlternativePayments(payments));

  typeof callback === 'function' && callback(response);
};

export const doCreateAlternativePayments = (payload, callback) => async (dispatch, getState) => {
  let response;

  try {
    response = await createAlternativePayments(payload);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) return;

  const {
    alternativePayments: { alternativePayments },
  } = getState();
  const newData = [...alternativePayments, response.data];
  dispatch(setAlternativePayments(newData));
};

export const doUpdateAlternativePayments = (payload, callback) => async (dispatch, getState) => {
  let response;

  try {
    response = await batchUpdateAlternativePayments(payload);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) return;
  const {
    alternativePayments: { alternativePayments },
  } = getState();
  const newData = [...alternativePayments];
  payload.forEach((item) => {
    const index = newData.findIndex((_) => _.id === item.id);
    if (index > -1) {
      newData[index] = { ...newData[index], ...item };
    }
  });
  newData.sort((a, b) => a.sequence - b.sequence);
  dispatch(setAlternativePayments(newData));
};

export const doDeleteAlternativePayments = (id, callback) => async (dispatch, getState) => {
  let response;

  try {
    response = await deleteAlternativePayments(id);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) return;
  const {
    alternativePayments: { alternativePayments },
  } = getState();
  const newData = [...alternativePayments];
  const index = newData.findIndex((_) => _.id === id);
  if (index > -1) {
    newData.splice(index, 1);
  }
  dispatch(setAlternativePayments(newData));
};
