import mixpanelBrowser from 'mixpanel-browser';
import { mixpanelToken } from '../consts/third_party';
import { getUserInfo } from '../utils/utils';
import { getSessionItem, KEYS } from '../store/storage';

mixpanelBrowser.init(mixpanelToken, {
  debug: process.env.STAGING_ENV === 'production' ? false : true,
});

class Mixpanel {
  enableTracking = false; //default false

  toggleTracking(enableTracking) {
    this.enableTracking = enableTracking;
  }

  updateUser() {
    const currentUser = getUserInfo();
    const restaurantId = getSessionItem(KEYS.restaurantId);
    const { family_name, given_name, email, phone_number, created_at, sub } = currentUser;
    this.enableTracking &&
      mixpanelBrowser &&
      mixpanelBrowser.people.set({
        $first_name: given_name,
        $last_name: family_name,
        $phone: phone_number,
        $created: created_at,
        $email: email,
        $restaurant_id: restaurantId,
      });
    mixpanelBrowser && this.identify(sub);
  }

  identify(userId) {
    this.enableTracking && mixpanelBrowser && mixpanelBrowser.identify(userId);
  }

  alias(userId) {
    this.enableTracking && mixpanelBrowser && mixpanelBrowser.alias(userId, mixpanelBrowser.distinctId);
  }

  /**
   * @param {object} props
   * @param {string} props.name
   * @param {object} props.properties
   * @param {RequestOptions | Callback} [props.callback]
   */
  track({ enableTracking = false, name, properties, callback }) {
    if (this.enableTracking) {
      mixpanelBrowser && mixpanelBrowser.track(name, properties, callback);
    } else {
      enableTracking && mixpanelBrowser && mixpanelBrowser.track(name, properties, callback);
    }
  }
}

export const mixpanel = new Mixpanel();
