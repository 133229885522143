import Pusher from 'pusher-js';
import { pushAppKeys } from '../consts/third_party';
import { getPusherAuthorToken } from '../services/login';
import EventEmitter, { EVENTS } from '../utils/eventEmitter';
import { getAppInfos } from '../utils/utils';

const privatePrefix = 'private-';
const channelSubscriptionSucceedEvent = 'pusher:subscription_succeeded';
const floorItemUpdatedEvent = 'floor_item:updated';
const restaurantChannelPrefix = 'restaurant';
let restaurantChannel = null;

let shouldReconnect = false;

const authorizer = (channel, options) => {
  return {
    authorize: async (socketId, callback) => {
      let response;

      try {
        response = await getPusherAuthorToken({
          socket_id: socketId,
          channel_name: channel.name,
        });
      } catch (e) {
        response = { success: false };
      }

      if (response.success) {
        callback(null, response.data);
        return;
      }

      callback(
        new Error('get push auth token failed', {
          auth: '',
        })
      );
    },
  };
};

export const subscribeRestaurantChannel = (restaurantId) => {
  if (restaurantChannel || !restaurantId || !pusher) return;
  const testChannel = pusher.subscribe(`${privatePrefix}ping`);
  testChannel.bind(channelSubscriptionSucceedEvent, () => {
    console.log('Successfully subscribe a private channel');
  });

  restaurantChannel = pusher.subscribe(`${restaurantChannelPrefix}_${restaurantId}`);
  restaurantChannel.bind(channelSubscriptionSucceedEvent, () => {
    console.log(`Successfully subscribe restaurant_${restaurantId} channel`);
  });
  restaurantChannel.bind(floorItemUpdatedEvent, (data) => {
    console.log('received floor item updated event: ', data);
    EventEmitter.emit(EVENTS.FLOOR_ITEM_UPDATED, data);
  });
};

export const unsubscribeRestaurantChannel = (restaurantId) => {
  if (!restaurantChannel || !restaurantId || !pusher) return;
  restaurantChannel.unsubscribe();
  console.log(`restaurant_${restaurantId} channel unsubscribed`);
  restaurantChannel = null;
};

export const resubscribeRestaurantChannel = (newRestaurantId, oldRestaurantId) => {
  unsubscribeRestaurantChannel(oldRestaurantId);
  subscribeRestaurantChannel(newRestaurantId);
};

let pusher;

if (
  typeof window !== 'undefined' &&
  process.env.NODE_ENV !== 'test' &&
  window.location.pathname !== '/receipt' &&
  window.location.pathname.indexOf('/order-status-board') === -1
) {
  const appInfo = getAppInfos();
  if (!appInfo.isApp && !pusher) {
    pusher = new Pusher(pushAppKeys.key, {
      cluster: pushAppKeys.cluster,
      authorizer,
    });

    pusher.connection.bind('connected', () => {
      console.log(shouldReconnect ? 'pusher reconnected!' : 'pusher connected!');
      shouldReconnect = false;
    });
    pusher.connection.bind('disconnected', () => {
      shouldReconnect = true;
      console.log('pusher disconnected!');
    });
  }
}

export default pusher;
