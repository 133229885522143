import { POST } from 'src/utils/request';
import { API_PREFIX, AUTH_API_PREFIX, CDS_API_PREFIX } from 'src/consts';
import { AUTH_CLIENT_ID } from 'src/consts/third_party';

export function getAuthorToken({ email, password }) {
  return POST(
    `${API_PREFIX}/v2/login`,
    {
      email,
      password,
      grant_type: 'password',
    },
    {
      noNeedLogin: true,
    }
  );
}

export function getOauthToken({ code, redirect_uri }) {
  return POST(
    `${AUTH_API_PREFIX}/oauth/token`,
    {
      client_id: AUTH_CLIENT_ID,
      grant_type: 'authorization_code',
      code,
      redirect_uri,
    },
    {
      noNeedLogin: true,
    }
  );
}

export function getPusherAuthorToken(data) {
  return POST(`${API_PREFIX}/v1/pusher/auth`, data, {
    silence: true,
    noNeedLogin: true,
  });
}

export function getCdsPusherAuthorToken(data) {
  return POST(`${CDS_API_PREFIX}/v1/pusher/auth`, data, {
    silence: true,
    noNeedLogin: true,
  });
}
