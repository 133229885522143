import { GET } from '../utils/request';
import { API_PREFIX, AUTH_API_PREFIX, CLIENT_ID } from '../consts';

export const URL_PERMISSION_TREE = '/v1/permissions/system/tree';

// query system permission tree
export function querySystemPermissionTree() {
  return GET(`${API_PREFIX}${URL_PERMISSION_TREE}`);
}

// query user permission codes
export function queryUserPermissionCodes() {
  return GET(`${API_PREFIX}/v1/permissions/user/permissioncodes`);
}

export function queryRgUserRole() {
  return GET(
    `${AUTH_API_PREFIX}/api/v1/restaurateurs/restaurant_group_roles`,
    {},
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: false,
    }
  );
}
